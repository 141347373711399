import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import Seo from '../../components/Seo'
import SubLayout from '../../components/layouts/SubLayout'
import StyledMethodsAndFieldsPage from '../../components/styles/StyledMethodsAndFieldsPage'

const LearningMethodPage = ({ data }) => {
  const {
    title,
    slug,
    description,
    featuredImage,
    themeColour
  } = data.sanityLearningMethod

  const courses = data.allSanityCourse.nodes

  const coursesListElements = courses.map(course => (
    <li key={course.slug.current}><Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}/`}>{course.title}</Link></li>
  ))

  // const formatLearningMethodTitle = (title) => `${title}${title === 'Workshop' ? 's' : ' Courses'}`

  return (
    <SubLayout>
      <Seo title={title} pathname={`/learning-methods/${slug.current}`} />
      <StyledLearningMethodPage themeColour={themeColour}>
        <h1>{title}</h1>
        <p className="breadcrumbs">
          <Link to='/'>Home</Link> {`>`}&nbsp;
          <Link to='/learning-methods/'>Learning Methods</Link> {`>`}&nbsp;
          {title}
        </p>

        <section className="content">
          <img src={featuredImage.asset.url} alt={title} />
          <ReactMarkdown>{description}</ReactMarkdown>
        </section>

        <section className="courses">
          <h2>{title}</h2>

          <ul>
            {coursesListElements}
          </ul>
        </section>
      </StyledLearningMethodPage>
    </SubLayout>
  )
}

export const query = graphql`
  query LearningMethodQuery($id: String) {
    sanityLearningMethod(id: { eq: $id }) {
      title
      slug { current }
      description
      featuredImage {
        asset { url }
      }
      themeColour
    }
    allSanityCourse(
      filter: {courseStartDates: {elemMatch: {learningMethod: {id: {eq: $id}}}}}, 
      sort: {fields: title}
    ) {
      nodes {
        title
        slug { current }
        studyFields {
          slug { current }
        }
      }
    }
  }
`

const StyledLearningMethodPage = styled(StyledMethodsAndFieldsPage)`
  h1, h2 {
    display: inline-block;
    background-color: ${props => props.themeColour};
    color: #fafafa;
    padding: 0.4em 0.6em;
  }
`

export default LearningMethodPage
